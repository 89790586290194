#root {
    /* so the page appear full of body */
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /* need this position relative otherwise will overflow */
  }
  
  .clickable {
    cursor: pointer;
  }
  
  .tolink {
    color: #4b74ff;
  }